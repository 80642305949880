define('modules/constants',[],function() {
    return {
        EVENT_PLAYER_EMBED_PLAYER_FULLY_READY: 'PLAYER_EMBED_PLAYER_FULLY_READY',
        EVENT_PLAYER_TIME_UPDATE: 'PLAYER_TIME_UPDATE',
        EVENT_PLAYER_VIDEO_ENDED: 'PLAYER_VIDEO_ENDED',
        EVENT_PLAYER_VIDEO_PAUSED: 'PLAYER_VIDEO_PAUSED',
        EVENT_PLAYER_VIDEO_PLAYED: 'PLAYER_VIDEO_PLAYED',
        EVENT_EMBED_PLAYER_ADDED_TO_DOM: 'PLAYER_EMBED_PLAYER_ADDED_TO_DOM',
        LOADING_MODE_NORMAL: 'normal',
        LOADING_MODE_WITH_BACK_BTN: 'with-back-button'
    }
});
