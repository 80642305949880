define('modules/util',[],function() {
    return {
        // initialze
        init: function() {
            if (window.console) {
                // Only run on the first time through - reset this function to the appropriate console.log helper
                if (Function.prototype.bind) {
                    this.logIt = Function.prototype.bind.call(console.log, console);
                } else {
                    this.logIt = function() {
                        Function.prototype.apply.call(console.log, console, arguments);
                    };
                }
                this.logIt.apply(this, arguments);
            }
        },
        // get ie version
        getIEVersion: function() {
            var match = navigator.userAgent.match(/(?:MSIE |Trident\/.*; rv:)(\d+)/);
            return match ? parseInt(match[1]) : undefined;
        },
        // get url
        getUrl: function(url) {
            var url = url.replace(/https?:\/\//i, '');
            var url = url.replace('//', '');
            return url;
        },
        // console log
        logIt: function(msg) {

        }
    }
});
