define('modules/player',['modules/config', 'modules/constants', 'modules/util', 'modules/action', 'modules/events'], function(config, constants, util, action, events) {
    return {
        // events
        Event: {
            // subscribe event
            subscribe: function(eventName, callback) {
                events.subscribe(eventName, callback)
            },
            // unsubscribe to event
            unsubscribe: function(eventName, callback) {
                events.unsubscribe(eventName, callback)
            },
            // trigger event
            trigger: function(eventName, data) {
                events.trigger(eventName, data)
            }
        },
        // triggered when iframe added
        EMBED_PLAYER_ADDED_TO_DOM: constants.EVENT_EMBED_PLAYER_ADDED_TO_DOM,
        // triggered when player is fully ready
        EMBED_PLAYER_FULLY_READY: constants.EVENT_PLAYER_EMBED_PLAYER_FULLY_READY,
        // triggered on each time update
        TIME_UPDATE: constants.EVENT_PLAYER_TIME_UPDATE,
        // triggered on video ended
        VIDEO_ENDED: constants.EVENT_PLAYER_VIDEO_ENDED,
        // triggered on video paused
        VIDEO_PAUSED: constants.EVENT_PLAYER_VIDEO_PAUSED,
        // triggered on video played
        VIDEO_PLAYED: constants.EVENT_PLAYER_VIDEO_PLAYED,
        //init
        init: function() {
            var iframes = document.querySelectorAll('[data-type="touchcast"]');
            for (i = 0; i < iframes.length; i++) {
                // get player id
                var playerId = iframes[i].getAttribute("id");
                // get src
                var src = iframes[i].getAttribute("src");
                // get url
                var url = util.getUrl(src);
                // build player array
                events.buildPlayerArray(playerId, url);
            }
        },
        // get version
        getSDKVersion: function() {
            return config.version;
        },
        // add the iframe
        embed: function(params) {
            // init util
            util.init();
            // check if param is an object, if not object then its an error ofcourse :)
            if (typeof params != 'object') {
                util.logIt('Passed invalid parameter');
                return false;
            }
            // check if we have all necessary params
            if (typeof params.container != 'string' || !params.container || (typeof params.width != 'string' && typeof params.width != 'number') || !params.width || (typeof params.height != 'string' && typeof params.height != 'number') || !params.height || typeof params.playerId != 'string' || !params.playerId || typeof params.videoId != 'string' || !params.videoId) {
                util.logIt('Missing parameters');
                return false;
            }
            // check if there is a domain param as we may use this for pilots, dev , or www
            if (typeof params.domain != 'string' || !params.domain) {
                var url = config.domain + '/embed/' + params.videoId;
            } else {
                var url = params.domain + '/embed/' + params.videoId;
            }
            // OK abhi, this is something to check, it may be a class or id of container
            if (params.container.indexOf('.') != '-1') {
                var container = document.getElementsByClassName(params.container.replace('.', ''))[0];
            } else {
                var container = document.getElementById(params.container.replace('#', ''));
            }
            if (typeof params.themeConfiguration == 'object' && params.themeConfiguration) {
                // if param appended
                var paramAppended = false;
                var skincolor = params.themeConfiguration.skinColor;
                if (typeof skincolor == 'string') {
                    var paramAppended = true;
                    var url = url + '?skincolor=' + skincolor;
                }
                var autoplay = params.themeConfiguration.autoPlay;
                if (typeof autoplay == 'string') {
                    if (paramAppended) {
                        var url = url + '&autoplay=' + autoplay;
                    } else {
                        var url = url + '?autoplay=' + autoplay;
                    }
                    var paramAppended = true;
                }
                var end = params.themeConfiguration.end;
                if (typeof end == 'string') {
                    if (paramAppended) {
                        var url = url + '&end=' + autoplay;
                    } else {
                        var url = url + '?end=' + autoplay;
                    }
                    var paramAppended = true;
                }
                var btnPlay = params.themeConfiguration.btnPlay;
                if (typeof btnPlay == 'string') {
                    if (paramAppended) {
                        var url = url + '&play_button=' + btnPlay;
                    } else {
                        var url = url + '?play_button=' + btnPlay;
                    }
                    var paramAppended = true;
                }
                var btnVolume = params.themeConfiguration.btnVolume;
                if (typeof btnVolume == 'string') {
                    if (paramAppended) {
                        var url = url + '&volume_button=' + btnVolume;
                    } else {
                        var url = url + '?volume_button=' + btnVolume;
                    }
                    var paramAppended = true;
                }
                var progressBar = params.themeConfiguration.progressBar;
                if (typeof progressBar == 'string') {
                    if (paramAppended) {
                        var url = url + '&progressbar=' + progressBar;
                    } else {
                        var url = url + '?progressbar=' + progressBar;
                    }
                    var paramAppended = true;
                }
                var playerTime = params.themeConfiguration.playerTime;
                if (typeof playerTime == 'string') {
                    if (paramAppended) {
                        var url = url + '&player_time=' + playerTime;
                    } else {
                        var url = url + '?player_time=' + playerTime;
                    }
                    var paramAppended = true;
                }
                var btnReCast = params.themeConfiguration.btnReCast;
                if (typeof btnReCast == 'string') {
                    if (paramAppended) {
                        var url = url + '&recast_button=' + btnReCast;
                    } else {
                        var url = url + '?recast_button=' + btnReCast;
                    }
                    var paramAppended = true;
                }
                var btnComment = params.themeConfiguration.btnComment;
                if (typeof btnComment == 'string') {
                    if (paramAppended) {
                        var url = url + '&comments_button=' + btnComment;
                    } else {
                        var url = url + '?comments_button=' + btnComment;
                    }
                    var paramAppended = true;
                }
                var btnInfo = params.themeConfiguration.btnInfo;
                if (typeof btnInfo == 'string') {
                    if (paramAppended) {
                        var url = url + '&info_button=' + btnInfo;
                    } else {
                        var url = url + '?info_button=' + btnInfo;
                    }
                    var paramAppended = true;
                }
                var btnShare = params.themeConfiguration.btnShare;
                if (typeof btnShare == 'string') {
                    if (paramAppended) {
                        var url = url + '&share_button=' + btnShare;
                    } else {
                        var url = url + '?share_button=' + btnShare;
                    }
                    var paramAppended = true;
                }
                var startTime = params.themeConfiguration.startTime;
                if (typeof startTime == 'string') {
                    if (paramAppended) {
                        var url = url + '&t=' + startTime;
                    } else {
                        var url = url + '?t=' + startTime;
                    }
                    var paramAppended = true;
                }
                var btnFullScreen = params.themeConfiguration.btnFullScreen;
                if (typeof btnFullScreen == 'string') {
                    if (paramAppended) {
                        var url = url + '&fullscreen_button=' + btnFullScreen;
                    } else {
                        var url = url + '?fullscreen_button=' + btnFullScreen;
                    }
                    var paramAppended = true;
                }
                var infoBoxShareViewsRecastCounts = params.themeConfiguration.infoBoxShareViewsRecastCounts;
                if (typeof infoBoxShareViewsRecastCounts == 'string') {
                    if (paramAppended) {
                        var url = url + '&infobox_counts=' + infoBoxShareViewsRecastCounts;
                    } else {
                        var url = url + '?infobox_counts=' + infoBoxShareViewsRecastCounts;
                    }
                    var paramAppended = true;
                }
                var infoBoxBookmarkButton = params.themeConfiguration.infoBoxBookmarkButton;
                if (typeof infoBoxBookmarkButton == 'string') {
                    if (paramAppended) {
                        var url = url + '&infobox_bookmark=' + infoBoxBookmarkButton;
                    } else {
                        var url = url + '?infobox_bookmark=' + infoBoxBookmarkButton;
                    }
                    var paramAppended = true;
                }
                var infoBoxFollowButton = params.themeConfiguration.infoBoxFollowButton;
                if (typeof infoBoxFollowButton == 'string') {
                    if (paramAppended) {
                        var url = url + '&infobox_follow=' + infoBoxFollowButton;
                    } else {
                        var url = url + '?infobox_follow=' + infoBoxFollowButton;
                    }
                    var paramAppended = true;
                }
                var infoBoxFlagButton = params.themeConfiguration.infoBoxFlagButton;
                if (typeof infoBoxFlagButton == 'string') {
                    if (paramAppended) {
                        var url = url + '&infobox_flag=' + infoBoxFlagButton;
                    } else {
                        var url = url + '?infobox_flag=' + infoBoxFlagButton;
                    }
                    var paramAppended = true;
                }
                var lang = params.themeConfiguration.lang;
                if (typeof lang == 'string') {
                    if (paramAppended) {
                        var url = url + '&lang=' + lang;
                    } else {
                        var url = url + '?lang=' + lang;
                    }
                    var paramAppended = true;
                }
                var controlsColor = params.themeConfiguration.controlsColor;
                if (typeof controlsColor == 'string') {
                    if (paramAppended) {
                        var url = url + '&controlsColor=' + controlsColor;
                    } else {
                        var url = url + '?controlsColor=' + controlsColor;
                    }
                    var paramAppended = true;
                }
                var controlsDividerColor = params.themeConfiguration.controlsDividerColor;
                if (typeof controlsDividerColor == 'string') {
                    if (paramAppended) {
                        var url = url + '&controlsDividerColor=' + controlsDividerColor;
                    } else {
                        var url = url + '?controlsDividerColor=' + controlsDividerColor;
                    }
                    var paramAppended = true;
                }
                var playerBackgroundColor = params.themeConfiguration.playerBackgroundColor;
                if (typeof playerBackgroundColor == 'string') {
                    if (paramAppended) {
                        var url = url + '&playerBackgroundColor=' + playerBackgroundColor;
                    } else {
                        var url = url + '?playerBackgroundColor=' + playerBackgroundColor;
                    }
                    var paramAppended = true;
                }
            }

            // lets add the iframe 
            var iframe = document.createElement("iframe");
            iframe.setAttribute("height", params.height);
            iframe.setAttribute("width", params.width);
            iframe.setAttribute("frameborder", 0);
            iframe.setAttribute("allowtransparency", true);
            iframe.setAttribute("allowfullscreen", true);
            iframe.setAttribute("scrolling", "no");
            iframe.setAttribute("id", params.playerId);
            iframe.setAttribute("src", url);
            iframe.style.minHeight = '265px';
            // append the iframe
            container.appendChild(iframe);
            // trigger element added event
            events.trigger(constants.EVENT_EMBED_PLAYER_ADDED_TO_DOM, '{"videoId":"' + params.videoId + '","playerId":"' + params.playerId + '"}');
            // get url
            var url = util.getUrl(url);
            //build player elements array
            events.buildPlayerArray(params.playerId, url);
        },
        doAction: function(params) {
            // check if param is an object, if not object then its an error ofcourse :)
            if (typeof params != 'object') {
                util.logIt('Passed invalid parameter');
                return false;
            }
            // check for all necessary params
            if (typeof params.action != 'string' || !params.action || typeof params.playerId != 'string' || !params.playerId) {
                util.logIt('Missing action parameter');
                return false;
            }
            // ok lets see what type of action is it
            switch (params.action) {
                // pause video
                case 'pauseVideo':
                    // call action to pause video 
                    action.pauseVideo(params.playerId);
                    break;
                    // resume video
                case 'resumeVideo':
                    // call action to resume video 
                    action.resumeVideo(params.playerId);
                    break;
                    // toggle state of playback
                case 'toggleVideoPlayback':
                    // call action to toggle playback 
                    action.toggleVideoPlayback(params.playerId);
                    break;
                    // seek video
                case 'seekVideo':
                    if (typeof params.seekTime == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    // call action to seek video 
                    action.seekVideo(params.playerId, params.seekTime);
                    break;
                    // restart Video
                case 'restartVideo':
                    if (typeof params.autoPlay == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    // call action to restart video 
                    action.restartVideo(params.playerId, params.autoPlay);
                    break;
                    // mute video
                case 'muteVideo':
                    // call action to mute 
                    action.muteVideo(params.playerId);
                    break;
                    // unmute video
                case 'unmuteVideo':
                    // call action to unmute 
                    action.unmuteVideo(params.playerId);
                    break;
                    // set volume
                case 'setVolume':
                    if (typeof params.volume == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    if (params.volume < 0 || params.volume > 1) {
                        util.logIt('Volume should be between 0 and 1');
                        return false;
                    }
                    // call action to set volume
                    action.setVolume(params.playerId, params.volume);
                    break;
                    // load new TC
                case 'loadTouchcastVideo':
                    if (typeof params.videoId == 'undefined' || typeof params.loadingMode == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    if (params.loadingMode != constants.LOADING_MODE_NORMAL && params.loadingMode != constants.LOADING_MODE_WITH_BACK_BTN) {
                        util.logIt('Invalid loading mode');
                        return false;
                    }
                    // call action to load new TC
                    action.loadTouchcastVideo(params.playerId, params.videoId, params.loadingMode);
                    break;
                    // disable player controls
                case 'disableControls':
                    if (typeof params.list == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    if (params.list == 'all' && typeof params.text == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    // call action to disable controls
                    action.disableControls(params.playerId, params.list, params.text);
                    break;
                    // enable player controls
                case 'enableControls':
                    if (typeof params.list == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    // call action to enable controls
                    action.enableControls(params.playerId, params.list);
                    break;
                case 'animatePlayer':
                    if (typeof params.value == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    if (typeof params.position == 'undefined') {
                        util.logIt('Missing required parameter');
                        return false;
                    }
                    // call action to enable controls
                    action.animatePlayer(params.playerId, params.value, params.position);
                    break;
                default:
                    util.logIt('Invalid action');
            }
            return true;
        }
    }
});
