define('modules/events',['modules/constants', 'modules/util'], function(constants, util) {
    return {
        isSubscribed: false,
        subscribedArray: [],
        playerArray: [],
        // build player array
        buildPlayerArray: function(playerId, src) {
            this.playerArray[src] = playerId;
        },
        //extend
        extend: function(out) {
            out = out || {};

            for (var i = 1; i < arguments.length; i++) {
                if (!arguments[i])
                    continue;

                for (var key in arguments[i]) {
                    if (arguments[i].hasOwnProperty(key))
                        out[key] = arguments[i][key];
                }
            }

            return out;
        },
        // emit event
        trigger: function(eventName, data) {
            var action = {
                name: eventName,
                action: data,
                frameLoc: window.frames.location.href
            };
            var dataStr = JSON.stringify(action);
            window.postMessage(dataStr, "*");
        },
        // callback 
        callback: function(event, callback) {
            var self = this;
            var data = JSON.parse(event.data);
            var eventBuild = {
                type: data.name
            }
            if (self.subscribedArray.indexOf(data.name) != -1) {
                var playerId = '';
                var eventSource = event.origin;
                var url = util.getUrl(data.frameLoc);
                // check if we have player id in action
                if (JSON.parse(data.action).playerId) {
                    var playerId = JSON.parse(data.action).playerId;
                } else {
                    var playerId = self.playerArray[url];
                }
                var dataStr = JSON.stringify(self.extend(JSON.parse(data.action), {
                    'playerId': playerId
                }));
                callback(eventBuild, dataStr);
            }
        },
        // subscribe event
        subscribe: function(subscribedEventName, callback) {
            var self = this;
            if (self.isSubscribed == false) {
                if (window.addEventListener) {
                    window.addEventListener("message", function(e) {
                        self.callback(e, callback)
                    }, false);
                } else {
                    window.attachEvent("onmessage", function(e) {
                        self.callback(e, callback)
                    });
                }
                self.isSubscribed = true;
            }
            self.subscribedArray.push(subscribedEventName);
        },
        // unsubscribe event
        unsubscribe: function(subscribedEventName, callback) {
            var self = this;
            self.subscribedArray.pop(subscribedEventName);
        }
    }
});
