define('modules/action',['modules/constants', 'modules/util'], function(constants, util) {
    return {
        sendMessage: function(playerId, data) {
            var target = document.getElementById(playerId);
            var dataStr = JSON.stringify(data);
            target.contentWindow.postMessage(dataStr, "*");
        },
        pauseVideo: function(playerId) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-pauseVideo'
            };
            this.sendMessage(playerId, action);
        },
        resumeVideo: function(playerId) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-resumeVideo'
            };
            this.sendMessage(playerId, action);
        },
        toggleVideoPlayback: function(playerId) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-toggleVideoPlayback'
            };
            this.sendMessage(playerId, action);
        },
        seekVideo: function(playerId, time) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-seekVideo',
                seekTime: time
            };
            this.sendMessage(playerId, action);
        },
        restartVideo: function(playerId, autoplay) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-restartVideo',
                autoPlay: autoplay ? true : false
            };
            this.sendMessage(playerId, action);
        },
        muteVideo: function(playerId) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-muteVideo'
            };
            this.sendMessage(playerId, action);
        },
        unmuteVideo: function(playerId) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-unmuteVideo'
            };
            this.sendMessage(playerId, action);
        },
        setVolume: function(playerId, volume) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-setVolume',
                volume: volume
            };
            this.sendMessage(playerId, action);
        },
        loadTouchcastVideo: function(playerId, id, mode) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-loadTouchcastVideo',
                videoId: id,
                loadingMode: mode,
                directMode: 1
            };
            this.sendMessage(playerId, action);
        },
        disableControls: function(playerId, list, text) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-disableControls',
                list: list,
                text: text
            };
            this.sendMessage(playerId, action);
        },
        enableControls: function(playerId, list) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-enableControls',
                list: list
            };
            this.sendMessage(playerId, action);
        },
        animatePlayer: function(playerId, value, position) {
            var action = {
                name: 'TouchCast Player Action',
                action: 'tc-animatePlayer',
                value: value,
                position: position
            };
            this.sendMessage(playerId, action);
        }
    }
});
