requirejs.config({
    baseUrl: 'src',
    paths: {
    },
    map: {
    }
});
requirejs(["main"]);
define("app", function(){});

